<template>
  <div>
    <button
      class="close-post"
      :class="[
        'button-square fixed top-0 left-17 md:left-0 text-center md:px-6 w-17 h-17',
        'md:h-23 md:w-auto block bg-secondary cursor-pointer text-sm font-bold z-20'
      ]"
      title="Close overlay"
      @click="closePostOverlay"
    >
      <div class="close-icon flex flex-col m-auto justify-between">
        <div class="close-icon__item close-icon__item--top" />
        <div class="close-icon__item close-icon__item--btm" />
      </div>
      <span class="mt-3 hidden md:block">
        Close
      </span>
    </button>
    <div class="overlay-post bg-accent-1 absolute w-full h-full z-10">
      <Post :post="post" :overlay-active="true" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import Post from '@/components/posts/Post.vue'
let tl = null

export default {
  name: 'OverlayPost',

  components: {
    Post
  },

  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },

  beforeUnmount() {
    this.$store.commit('toggleHotspotContent', null)
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
  },

  methods: {
    closePostOverlay() {
      tl.timeScale(2.5).reverse()
      tl.eventCallback('onReverseComplete', () => {
        this.$emit('closePostOverlay')
        this.$store.commit('toggleHotspotContent', null)
      })
    },
    loadTimeline() {
      tl = gsap.timeline({ paused: true })
      tl.fromTo('.overlay-post', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0, duration: 0.4, ease: 'power1.inOut' }, 1)
      tl.fromTo('.close-post', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 0.2, duration: 0.4, ease: 'power1.inOut' }, 1)
    }
  }
}
</script>
