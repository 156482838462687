<template>
  <div class="max-w-3xl mx-auto px-5 pt-28 md:pt-32 pb-20 text-white">
    <h1 class="gallery-heading mb-8 text-5xl athelas-font leading-none">
      {{ htmlDecode(post.title.rendered) }}
    </h1>
    <section
      id="rvCarousel"
      ref="RvCarousel"
      :aria-label="arialabel"
      aria-roledescription="carousel"
      class="gallery no-touch mt-12 lg:mt-0"
      :class="[`rv-carousel--${sizing}`]"
      @mouseover="handleRotation(false)"
      @mouseleave="handleRotation(rotating ? true : false)"
    >
      <div :class="[`rv-carousel__inner--${sizing}`]">
        <button
          v-if="allowRotation"
          :aria-label="rotating ? stop : start"
          aria-controls="rvCarousel-items"
          class="rv-carousel__btn rv-carousel__btn--pause bg-accent-3 focus-b"
          @click=";(rotate = !rotate), (rotating = !rotating)"
          @focus="handleRotation(false)"
          @blur="handleRotation(rotating ? true : false)"
        >
          <IconBase
            :icon="rotating ? 'PauseIcon' : 'BackIcon'"
            :color="`white`"
            :size="`1.5rem`"
            class="rotate-180"
          />
        </button>

        <!-- Left right buttons -->
        <ul class="text-white">
          <li>
            <button
              aria-label="Previous Slide"
              aria-controls="rvCarousel-items"
              class="rv-carousel__btn rv-carousel__btn--previous pr-1 py-2 focus-b"
              @click="handlePrevNext('prev')"
              @focus="handleRotation(false)"
              @blur="handleRotation(rotating ? true : false)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="6.738" height="12.409" viewBox="0 0 6.738 12.409" v-bind:svg-inline="''" v-bind:class="'h-full w-full fill-current rotate-180'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.3.097l-.2.2A.334.334 0 00.1.77l5.436 5.433L.1 11.641a.334.334 0 000 .468l.2.2a.334.334 0 00.468 0L6.64 6.442a.334.334 0 000-.473L.768.1A.332.332 0 00.3.097z"/></svg>
            </button>
          </li>
          <li>
            <button
              aria-label="Next Slide"
              aria-controls="rvCarousel-items"
              class="rv-carousel__btn rv-carousel__btn--next pl-1 py-2 focus-b"
              @click="handlePrevNext('next')"
              @focus="handleRotation(false)"
              @blur="handleRotation(rotating ? true : false)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="6.738" height="12.409" viewBox="0 0 6.738 12.409" v-bind:svg-inline="''" v-bind:class="'h-full w-full fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.3.097l-.2.2A.334.334 0 00.1.77l5.436 5.433L.1 11.641a.334.334 0 000 .468l.2.2a.334.334 0 00.468 0L6.64 6.442a.334.334 0 000-.473L.768.1A.332.332 0 00.3.097z"/></svg>
            </button>
          </li>
        </ul>

        <div
          id="rvCarousel-items"
          ref="RvCarouselItems"
          :aria-live="rotate ? 'off' : 'polite'"
          class="rv-carousel__items"
        >
          <TransitionGroup name="rv-carousel" mode="in-out">
            <div
              v-for="(image, i) in gallery"
              v-show="currentIndex === i"
              :key="`${image.id}`"
              role="group"
              aria-roledescription="slide"
              class="rv-carousel__item"
            >
              <img
                :src="lazyLoad.includes(i) ? image.sizes.large : ''"
                :alt="`Gallery item ${i + 1} of ${gallery.length}, ${image.alt}`"
                :class="[`rv-carousel__image--${sizing}`]"
              />
              <div v-if="titles" class="rv-carousel__info text-white">
                <h3
                  v-show="image.title"
                  class="rv-carousel__title bg-accent-3-90 py-1 px-4 font-normal"
                >
                  <span class="carousel__count">
                    {{ currentIndex + 1 }}/{{ gallery.length }} -
                  </span>
                  {{ image.title }}
                </h3>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </section>
    <div class="gallery-caption mt-20 font-normal" v-html="post.description" />
  </div>
</template>

<script>
import { gsap } from 'gsap'

import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'RvCarousel',

  components: {
    IconBase
  },

  props: {
    arialabel: {
      type: String,
      default: 'Gallery'
    },
    post: {
      type: Object,
      default: () => {}
    },
    titles: {
      type: Boolean,
      default: true
    },
    sizing: {
      type: String,
      default: 'scale'
    },
    allowRotation: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    currentIndex: 0,
    rotate: false,
    rotating: false,
    interval: null,
    stop: 'Stop automatic slide show',
    start: 'Start automatic slideshow',
    touchstartX: 0,
    touchendX: 0,
    isTouch: false,
    lazyLoad: [0]
  }),

  computed: {
    swipeZone() {
      return this.$refs.RvCarouselItems
    },

    gallery() {
      return this.post.gallery
    }
  },

  watch: {
    rotate: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.interval = setInterval(() => {
            this.currentIndex
              = this.currentIndex + 1 < this.gallery.length
                ? this.currentIndex + 1
                : 0
            this.handleLazyLoad()
          }, 3000)
        } else {
          clearInterval(this.interval)
        }
      }
    }
  },

  mounted() {
    this.addListeners()

    if (this.allowRotation) {
      this.rotate = true
      this.rotating = true
    }

    gsap.fromTo('.gallery-heading', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.8, ease: 'power1.inOut' })
    gsap.fromTo('.gallery', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.6, duration: 0.8, ease: 'power1.inOut' })
    gsap.fromTo('.gallery-caption', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.8, duration: 0.8, ease: 'power1.inOut' })
  },

  beforeUnmount() {
    window.removeEventListener('keyup', this.handleArrowKeys)
    window.removeEventListener('touchstart', this.handleTouch)
  },

  methods: {
    handlePrevNext(direction) {
      const length = this.gallery.length
      const index = this.currentIndex

      if (this.isTouch) {
        this.rotate = false
        this.rotating = false
      }

      switch (direction) {
      case 'next':
        this.currentIndex = (index + 1) % length
        break
      case 'prev':
        this.currentIndex = (index === 0 && length - 1) || index - 1
        break
      default:
        this.currentIndex = index
      }

      this.handleLazyLoad()
    },
    handleArrowKeys() {
      switch (event.keyCode) {
      case 39:
        this.handlePrevNext('next')
        break
      case 37:
        this.handlePrevNext('prev')
        break
      default:
      }
    },
    handleRotation(value) {
      if (!this.isTouch) {
        this.rotate = value
      }
    },
    addListeners() {
      window.addEventListener('keyup', this.handleArrowKeys)
      window.addEventListener('touchstart', this.handleTouch)

      this.swipeZone.addEventListener(
        'touchstart',
        (event) => {
          this.touchstartX = event.changedTouches[0].screenX
        },
        false
      )

      this.swipeZone.addEventListener(
        'touchend',
        (event) => {
          this.touchendX = event.changedTouches[0].screenX
          this.handleSwipe()
        },
        false
      )
    },
    handleTouch() {
      window.removeEventListener('touchstart', this.handleTouch)
      this.$refs.RvCarousel.classList.remove('no-touch')
      this.isTouch = true
    },
    handleSwipe() {
      if (this.isTouch) {
        this.rotate = false
        this.rotating = false
      }

      if (this.touchendX < this.touchstartX) {
        this.handlePrevNext('prev')
      }

      if (this.touchendX > this.touchstartX) {
        this.handlePrevNext('next')
      }
    },
    handleLazyLoad() {
      if (!this.lazyLoad.includes(this.currentIndex)) {
        this.lazyLoad.push(this.currentIndex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rv-carousel--scale {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  z-index: 1;
  width: 100%;
}

.rv-carousel__inner--scale {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.rv-carousel__btn {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: all 0.3s;
  z-index: 10;
  background-color: var(--color-accent-1);

  @screen md {
    width: 3.5rem;
    height: 3.5rem;
    background-color: transparent;
  }
}
.rv-carousel__btn-list {
  display: flex;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.rv-carousel__btn--pause {
  top: 0;
}
.rv-carousel__btn--previous {
  left: 0%;
  top: 45%;
  transform: translateY(-45%);
  @screen md {
    left: -12%;
  }
}
.rv-carousel__btn--next {
  right: 0%;
  top: 45%;
  transform: translateY(-45%);
  @screen md {
    right: -12%;
  }
}
.rv-carousel__items {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rv-carousel__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.rv-carousel__image--scale {
  height: 100%;
  margin: auto;
  width: auto;
}
.rv-carousel__image--fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.rv-carousel__info {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  padding: 0.5rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

/* Transitions */
.rv-carousel-enter-active,
.rv-carousel-leave-active {
  transition: opacity 0.6s;
}
.rv-carousel-enter,
.rv-carousel-leave-to {
  opacity: 0;
}
</style>
