<template>
  <div class="max-w-3xl mx-auto px-5 pt-28 md:pt-32 pb-20 text-white">
    <h1 class="mb-8 text-5xl athelas-font leading-none">
      {{ htmlDecode(post.title.rendered) }}
    </h1>
    <ul
      v-if="post.transcription || postVideo.audio_described"
      class="lg:flex lg:justify-between p-x lg:px-1 mb-4"
    >
      <li v-if="post.transcription">
        <button
          aria-controls="video-transcription"
          data-gtm-transcription="transcription"
          :aria-expanded="transcription"
          class="mt-2 focus-a underline"
          :class="[textcolor]"
          @click="transcription = !transcription"
        >
          {{ transcription ? 'Hide transcription' : 'Show transcription' }}
        </button>
      </li>
      <li v-if="postVideo.audio_described">
        <button
          class="mt-2 focus-a underline"
          :class="[textcolor]"
          data-gtm-audio-described="videoIsAudioDescribed"
          @click="toggleAudioDescribed"
        >
          {{ video.text }}
        </button>
      </li>
    </ul>
    <Expand v-show="transcription">
      <div
        id="video-transcription"
        class="tiny-mce mb-6 p-x lg:px-0 lg:mx-0 py-4 overflow-auto"
        :class="[textcolor]"
        v-html="post.transcription"
      />
    </Expand>
    <section aria-label="Video player" class="w-full h-full">
      <div :key="post.title.rendered" class="rv-video__outer">
        <iframe
          v-if="!isKaltura"
          class="rv-video__inner"
          :src="video.src"
          type="text/html"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          title="Video player"
        />

        <iframe
          v-if="isKaltura"
          id="kaltura_player"
          class="rv-video__inner"
          :src="video.src"
          width="100%"
          height="100%"
          allowfullscreen
          allow="autoplay *; fullscreen *; encrypted-media *"
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
          frameborder="0"
          title="Kaltura Player"
        />
      </div>
    </section>
    <div class="mt-8" v-if="post.description" v-html="post.description" />
  </div>
</template>

<script>
import Expand from '@/components/utility/Expand.vue'

export default {
  name: 'Video',

  components: {
    Expand
  },

  props: {
    post: {
      type: Object,
      default: () => {}
    },
    textcolor: {
      type: String,
      default: 'text-white'
    }
  },

  data: () => ({
    transcription: false,
    videoIsAudioDescribed: false
  }),

  computed: {
    isKaltura() {
      return this.postVideo.player === 'kaltura'
    },

    postVideo() {
      return this.post.video
    },

    video() {
      const src = this.postVideo.audio_described && this.videoIsAudioDescribed
        ? this.postVideo.audio_described
        : this.postVideo.video

      let playerSrc

      switch (this.postVideo.player) {
      case 'youtube':
        playerSrc = `https://www.youtube.com/embed/${this.getYouTubeId(
          src
        )}?rel=0&modestbranding=1&playsinline=1&enablejsapi=1&origin=${
          window.location.origin
        }`
        break
      case 'vimeo':
        playerSrc = `https://player.vimeo.com/video/${src}?portrait=0`
        break
      case 'kaltura':
        playerSrc = src
        break
      default:
      }

      const text
        = this.postVideo.audio_described && this.videoIsAudioDescribed
          ? 'Watch non audio described version'
          : this.postVideo.audio_described
            ? 'Watch audio described version'
            : ''

      return {
        src: playerSrc,
        text: text
      }
    }
  },

  methods: {
    getYouTubeId(inputUrl) {
      const outputUrl = inputUrl.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      )

      return undefined !== outputUrl[2]
        ? outputUrl[2].split(/[^0-9a-z_\  -]/i)[0]
        : outputUrl[0]
    },
    toggleAudioDescribed() {
      if (this.videoIsAudioDescribed) {
        this.videoIsAudioDescribed = false
      } else {
        this.videoIsAudioDescribed = true
      }
    }
  }
}
</script>

<style>
.rv-video__outer {
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.rv-video__inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
