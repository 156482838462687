<template>
  <div class="w-full md:w-3/12">
    <!-- Mobile -->
    <div class="fixed w-full bottom-0 bg-grey-500 overflow-hidden z-40 md:hidden">
      <!-- Top -->
      <button class="w-full min-h-16 px-4 py-2 flex md:hidden items-center text-left" @click="toggleSlideout" :title="htmlDecode(`Toggle ${navLabel}`)">
        <div class="pr-2 flex-grow">
          <p v-if="!sameHeadings && subheading" class="section-title text-sm font-bold leading-tight" v-html="subheading" />
          <h1 v-if="title" class="heading athelas-font text-lg font-normal leading-tight" v-html="title" />
        </div>
        <div class="flex-shrink-0">
          <IconBase
            icon="IconChevronRight"
            size="20px"
            class="transition duration-300"
            :class="slideoutOpen ? 'rotate-90' : 'rotate-270'"
          />
        </div>
      </button>
      <!-- Content -->
      <transition
        name="expand"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
      >
        <div v-show="slideoutOpen" class="overflow-hidden">
          <div class="slideout-content-mobile overflow-auto py-3 border-b-10" :style="`border-color: ${sectionColor};`">
            <p v-if="description" class="description font-normal text-xs mb-4 last:mb-0 px-4" v-html="description" />
            <nav v-if="$slots.links" :aria-label="htmlDecode(navLabel)">
              <slot name="links" />
            </nav>
          </div>
        </div>
      </transition>
    </div>
    <!-- Desktop -->
    <div class="r-cover pt-32 hidden md:block overflow-auto border-b-10 bg-grey-500" :style="`border-color: ${sectionColor};`">
      <div class="mb-4 last:mb-0 px-10">
        <div v-if="subheading || title" class="mb-4 last:mb-0">
          <p v-if="!sameHeadings && subheading" class="section-title relative mb-2 last:mb-0 text-sm font-bold leading-tight" v-html="subheading" />
          <h1 v-if="title" class="heading athelas-font text-2xl font-normal leading-tight" v-html="title" />
        </div>
        <slot name="excerpt" />
      </div>
      <nav class="slideout-content-desktop" :aria-label="htmlDecode( navLabel )">
        <slot name="links" />
      </nav>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'TourSlideout',

  components: {
    IconBase
  },

  props: {
    sectionColor: {
      type: String,
      default: '#ada2cf'
    },
    subheading: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    navLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      slideoutOpen: null
    }
  },

  computed: {
    sameHeadings() {
      return this.title === this.subheading
    }
  },

  methods: {
    toggleSlideout() {
      if (this.slideoutOpen) {
        this.slideoutOpen = false
      } else {
        this.slideoutOpen = true
      }
    },
    enter(element) {
      const width = getComputedStyle(element).width
      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'
      const height = getComputedStyle(element).height
      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0
      // Force repaint to make sure the
      const newHeight = getComputedStyle(element).height
      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height
      })
      // Return
      return newHeight
    },
    afterEnter(element) {
      element.style.height = 'auto'
    },
    leave(element) {
      const height = getComputedStyle(element).height
      element.style.height = height
      // Force repaint to make sure the
      // animation is triggered correctly.
      const newHeight = getComputedStyle(element).height
      requestAnimationFrame(() => {
        element.style.height = 0
      })
      // Return
      return newHeight
    }
  }
}
</script>

<style lang="scss" scoped>
/*
.expand-enter-active
.expand-leave-active
.expand-enter
.expand-leave-to
*/

.slideout-content-mobile {
  max-height: 50vh;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
</style>
