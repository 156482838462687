<template>
  <div class="r-cover bg-accent-1" :class="postType=== 'panorama' ? 'overflow-hidden' : 'overflow-auto'">
    <Article
      v-if="postType === 'post'"
      :post="post"
    />
    <Gallery
      v-else-if="postType === 'gallery'"
      :post="post"
    />
    <Video
      v-else-if="postType === 'video'"
      :post="post"
    />
    <Panorama
      v-else-if="postType === 'panorama'"
      :post="post"
      @hotSpotClick="handleHotspotClick"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/posts/Article.vue'
import Gallery from '@/components/posts/Gallery.vue'
import Video from '@/components/posts/Video.vue'
import Panorama from '@/components/posts/Panorama.vue'

export default {
  name: 'Post',

  components: {
    Article,
    Gallery,
    Video,
    Panorama
  },

  props: {
    post: {
      type: Object,
      default: () => {}
    },
    overlayActive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['getPostBySlug']),
    postObject() {
      return this.post ?? {}
    },
    postType() {
      return this.post.post_type ?? this.post.type
    }
  },

  methods: {
    handleHotspotClick(slug) {
      this.$emit('hotSpotClick', slug)
      const routeName = this.$route.name === 'tour-group' || this.$route.name === 'tour-hotspot' ? 'tour-hotspot' : 'hotspot'
      this.$router
        .push({
          name: routeName,
          params: {
            content: this.$route.params.content,
            hotspot: slug
          }
        })
    }
  }
}
</script>
