<template>
  <article class="overflow-auto max-w-3xl mx-auto px-5 pt-28 md:pt-32 pb-20 text-white">
    <h1 class="article-heading mb-8 text-5xl athelas-font leading-none">
      {{ htmlDecode(post.title.rendered) }}
    </h1>
    <div class="wysiwyg text-white" v-html="post.body" />
  </article>
</template>

<script>
import { gsap } from 'gsap'
let tl = null

export default {
  name: 'Article',

  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
  },

  watch: {
    $route(from, to) {
      this.$nextTick(() => {
        this.loadTimeline()
        tl.play()
      })
    }
  },

  methods: {
    loadTimeline() {
      tl = gsap.timeline({ paused: true })
      tl.fromTo('.article-heading', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.8, ease: 'power1.inOut' }, 1)
      tl.fromTo('.wysiwyg', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.6, duration: 0.8, ease: 'power1.inOut' }, 1)
    }
  }
}
</script>
