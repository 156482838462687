<template>
  <div v-if="panoMap && mapType === 'leaflet'" class="h-full relative z-0">
    <div
      id="map"
      ref="Map"
      class="rv-map__leaflet h-full w-full z-0"
      aria-label="Map with location"
    />
    <!-- Ctrl + shift + alt + u to get coords -->
    <div v-if="showLatLng" class="absolute bottom-56 right-6 p-3 bg-white text-sm">
      <p>Latitude: {{ Math.round(latLngCoords.lat) }}</p>
      <p>Longitude: {{ Math.round(latLngCoords.lng) }}</p>
    </div>
  </div>
  <div v-else-if="panoMap && googleMapsCenter">
    <GoogleMap
      v-if="googleMapsCenter"
      api-key="AIzaSyBlsNove1u3kFKLWk0Di8dA6QA1rIoaHs0"
      class="absolute w-full h-full"
      :center="googleMapsCenter"
      :zoom="mapZoom"
    >
      <Marker :options="{ position: googleMapsCenter }" />
    </GoogleMap>
  </div>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'
/* global L: false */
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'

export default {
  name: 'Map',

  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },

  components: { GoogleMap, Marker },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      map: '',
      latLngCoords: {
        lat: null,
        lng: null
      },
      showLatLng: false
    }
  },

  mounted() {
    if (this.panoMap && this.mapType === 'leaflet') {
      this.loadMap()
    }
    document.onkeyup = (e) => {
      if (e.ctrlKey && e.altKey && e.shiftKey && e.which === 85) {
        this.showLatLng = !this.showLatLng
      }
    }
  },

  watch: {
    post() {
      if (this.mapType === 'leaflet') {
        this.loadMap()
      }
    }
  },

  computed: {
    panoMap() {
      return this.post.map
    },
    mapZoom() {
      return this.panoMap.zoom ? parseInt(this.panoMap.zoom, 10) : 12
    },
    mapType() {
      return this.panoMap.type
    },
    googleMapsCenter() {
      return {
        lat: parseFloat(this.panoMap.lat),
        lng: parseFloat(this.panoMap.long)
      }
    },
    leafletPin() {
      return this.post.map.pin_location
    }
  },

  methods: {
    loadMap() {
      const imageSrc = this.panoMap.image.url
      const image = new Image()

      image.src = imageSrc
      image.alt = 'Aerial map of'
      // ${this.campus.title}

      image.onload = () => {
        this.initMap(this.panoMap.image.url)
      }
    },
    initMap(image) {
      this.bounds = [
        [-696, -1000],
        [696, 1000]
      ]
      this.map = L.map('map', {
        crs: L.CRS.Simple,
        zoomDelta: 0.25,
        zoomSnap: 0,
        wheelPxPerZoomLevel: 100,
        minZoom: -2.2,
        maxZoom: 1,
        maxBounds: [
          [-696, -1000],
          [696, 1000]
        ],
        doubleClickZoom: false
      })

      L.imageOverlay(image, this.bounds).addTo(this.map)

      this.map.fitBounds(this.bounds)

      this.markerLayer = L.layerGroup([]).addTo(this.map)

      this.addMarkers()

      // Beacuse the popup doesn't exist until clicked
      this.map.on('popupopen', this.bindPopupClick)
      this.map.on('popupclose', this.unbindPopupClick)

      // Just the utility to get coords
      this.map.on('dblclick', (e) => {
        this.latLngCoords = e.latlng
      })
    },
    addMarkers() {
      L.marker([
        this.leafletPin.lat,
        this.leafletPin.long
      ],
      {
        icon: L.icon({
          iconUrl: require('@/assets/icon-marker.svg'),
          iconSize: [50, 50]
        }),
        keyboard: false
      }
      ).addTo(this.map)
    }
  }
}
</script>

<style lang="scss" scoped>
#no-touch .rv-map__leaflet:focus:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  border: 3px solid var(--color-focus);
  z-index: 500;
}

.leaflet-container {
  /* background-color:rgba(187,226,207,1); */
  background-color: #fff;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-marker-icon {
  border-radius: 1000px;
}

.leaflet-marker-icon:focus {
  outline: none;
  /* border: 3px solid var(--color-focus); */
}

.leaflet-popup-tip {
  background: var(--color-primary);
}

.leaflet-popup-content {
  /* background: transparent; */
  margin: 0;
}

.leaflet-popup-content-wrapper {
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.map__marker-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.marker-cluster {
  background-color: rgba(212, 0, 114, 0.5);
}

.marker-cluster div {
  background-color: rgba(212, 0, 114, 1);
}

.marker-cluster span {
  color: white;
}
</style>
