<template>
  <div
    class="absolute flex items-center bottom-56 right-8"
    :class="[{ hidden: !panoAccessibilityControlsOpen }]"
  >
    <div class="column mr-9">
      <div class="flex justify-center">
        <button
          class="button-square bg-white w-9 h-9"
          title="Pan up"
          rounded
          aria-label="Pan up"
          @keydown="controlKeyDown($event, startPanUp)"
          @mousedown="startPanUp"
          @touchstart="startPanUp"
          @mouseup="stopVerticalPan"
          @keyup="stopVerticalPan"
          @mouseleave="stopVerticalPan"
          @touchend="stopVerticalPan"
          @touchmove="stopVerticalPan"
          @touchcancel="stopVerticalPan"
        >
          <IconBase
            icon="IconChevronRight"
            size="18px"
            class="rotate-270"
          />
        </button>
      </div>
      <div class="flex justify-center">
        <button
          class="button-square bg-white w-9 h-9 mr-5"
          title="Pan Left"
          rounded
          aria-label="Pan Left"
          @keydown="controlKeyDown($event, startPanLeft)"
          @mousedown="startPanLeft"
          @touchstart="startPanLeft"
          @mouseup="stopHorizontalPan"
          @keyup="stopHorizontalPan"
          @mouseleave="stopHorizontalPan"
          @touchend="stopHorizontalPan"
          @touchmove="stopHorizontalPan"
          @touchcancel="stopHorizontalPan"
        >
          <IconBase
            icon="IconChevronRight"
            size="18px"
            class="rotate-180 mt-1"
          />
        </button>
        <button
          class="button-square bg-white w-9 h-9 ml-5"
          title="Pan Right"
          rounded
          aria-label="Pan Right"
          @keydown="controlKeyDown($event, startPanRight)"
          @mousedown="startPanRight"
          @touchstart="startPanRight"
          @mouseup="stopHorizontalPan"
          @keyup="stopHorizontalPan"
          @mouseleave="stopHorizontalPan"
          @touchend="stopHorizontalPan"
          @touchmove="stopHorizontalPan"
          @touchcancel="stopHorizontalPan"
        >
          <IconBase
            icon="IconChevronRight"
            size="18px"
            class="mt-1"
          />
        </button>
      </div>
      <div class="flex justify-center">
        <button
          class="button-square bg-white w-9 h-9"
          title="Pan Down"
          rounded
          aria-label="Pan Down"
          @keydown="controlKeyDown($event, startPanDown)"
          @mousedown="startPanDown"
          @touchstart="startPanDown"
          @mouseup="stopVerticalPan"
          @keyup="stopVerticalPan"
          @mouseleave="stopVerticalPan"
          @touchend="stopVerticalPan"
          @touchmove="stopVerticalPan"
          @touchcancel="stopVerticalPan"
        >
          <IconBase
            icon="IconChevronRight"
            size="18px"
            class="rotate-90"
          />
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <button
        class="button-square zoom-in bg-white w-9 h-9"
        title="Zoom in"
        rounded
        aria-label="Zoom in"
        @keydown="controlKeyDown($event, startZoomIn)"
        @mousedown="startZoomIn"
        @touchstart="startZoomIn"
        @mouseup="stopZoom"
        @keyup="stopZoom"
        @mouseleave="stopZoom"
        @touchend="stopZoom"
        @touchmove="stopZoom"
        @touchcancel="stopZoom"
      >
        <span class="text-3xl leading-none">
          +
        </span>
      </button>
      <button
        class="button-square zoom-out bg-white w-9 h-9"
        title="Zoom Out"
        rounded
        aria-label="Zoom Out"
        @keydown="controlKeyDown($event, startZoomOut)"
        @mousedown="startZoomOut"
        @touchstart="startZoomOut"
        @mouseup="stopZoom"
        @keyup="stopZoom"
        @mouseleave="stopZoom"
        @touchend="stopZoom"
        @touchmove="stopZoom"
        @touchcancel="stopZoom"
      >
        <span class="block bg-black h-2px w-4 m-auto" />
      </button>
      <button
        class="button-square w-9 h-9"
        :class="[autoRotateActive ? 'bg-black' : 'bg-white']"
        rounded
        title="Toggle rotate mode"
        aria-label="Toggle rotate mode"
        @click="autoRotateToggle()"
      >
        <IconBase
          icon="IconRedo"
          size="18px"
          class="mt-2"
          :class="[{ 'text-white': autoRotateActive }]"
        />
      </button>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'AccessibilityControls',

  data() {
    return {
      moveForceVal: 0.2,
      inFullscreen: false,
      isFullscreenAvailable: false
    }
  },

  components: {
    IconBase
  },

  props: {
    pano: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    autoRotateActive: {
      get() {
        return this.$store.state.panoIsAutoRotating
      },

      set(payload) {
        this.$store.commit('setPanoAutorotate', payload)
      }
    },

    panoAccessibilityControlsOpen() {
      return this.$store.state.panoAccessibilityControlsOpen
    }
  },

  methods: {
    // DRY tools for Pan / Zoom controls
    controlKeyDown(event, callback) {
      const clickKeyCodes = [13, 32]
      const { which } = event || {}
      const isClickKey = clickKeyCodes.includes(which)
      if (isClickKey) {
        callback()
      }
    },

    moveForceCall(panoVar, moveForceString) {
      const { pano } = this
      if (!(pano || moveForceString)) {
        return
      }
      pano.call(`set(${panoVar}_moveforce, ${moveForceString});`)
    },

    // Pan
    startPanEvent(axis, operator) {
      this.moveForceCall(`${axis}lookat`, `${operator}${this.moveForceVal}`)
    },

    stopPanEvent(axis) {
      this.moveForceCall(`${axis}lookat`, 0)
    },

    stopVerticalPan() {
      this.stopPanEvent('v')
    },

    stopHorizontalPan() {
      this.stopPanEvent('h')
    },

    startPanUp() {
      this.startPanEvent('v', '-')
    },

    startPanLeft() {
      this.startPanEvent('h', '-')
    },

    startPanRight() {
      this.startPanEvent('h', '+')
    },

    startPanDown() {
      this.startPanEvent('v', '+')
    },

    // Zoom
    stopZoom() {
      this.moveForceCall('fov', 0)
    },

    startZoomIn() {
      this.moveForceCall('fov', `-${this.moveForceVal}`)
    },

    startZoomOut() {
      this.moveForceCall('fov', `+${this.moveForceVal}`)
    },

    // Auto-rotate
    autoRotateToggle(state) {
      const newState = state || !this.autoRotateActive
      this.pano.call(`set(autoRotate.enabled, ${newState});`)
      this.autoRotateActive = newState
    },

    autoRotateDisable() {
      this.autoRotateToggle('false')
    },

    autoRotateEnable() {
      this.autoRotateToggle('true')
    },

    toggleAccessibilityControls() {
      this.$store.commit('app/TOGGLE_PANO_ACCESSIBILITY_CONTROLS')
      this.pushToDataLayer({
        event: 'panoramaInteraction',
        interactionName: 'toggledAccessibilityControls'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-out {
  &:hover,
  &:focus {
    span {
      background-color: var(--color-white);
    }
  }
}

button:deep {
  &:hover,
  &:focus {
    background-color: var(--color-black);
    span {
      background-color: var(--color-black);
      g {
        color: var(--color-white);
      }
    }
  }
}
</style>
