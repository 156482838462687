<template>
  <div
    class="r-cover overflow-hidden"
  >
    <div
      :inert="mapOverlayActive"
      id="pano"
      class="r-cover z-0"
    />
    <AccessibilityControls :inert="mapOverlayActive" :pano="pano" />
    <!-- Panorama information -->
    <button
      v-if="post.pano_description"
      :inert="mapOverlayActive"
      class="button-square absolute bottom-20 md:bottom-8 left-8 w-16 h-16 text-center px-6 block bg-secondary cursor-pointer text-sm font-bold z-20"
      :aria-label="infoPanelActive ? 'Close panorama information panel' : 'Open panorama information panel'"
      :aria-expanded="infoPanelActive ? 'true' : 'false'"
      @click="infoPanelActive = !infoPanelActive"
    >
      <div class="flex flex-col m-auto justify-between leading-none">
        <span class="athelas-font text-6xl font-normal">i</span>
      </div>
    </button>
    <transition name="fade-left">
      <div
        v-if="post.pano_description"
        v-show="infoPanelActive && !mapOverlayActive"
        class="absolute left-8 right-8 bottom-20 md:bottom-8 md:w-1/2 max-h-40vh z-10 overflow-auto pt-6 px-6 md:pr-6 md:pl-24 pb-24 md:pb-6 bg-white font-normal z-10"
        v-html="post.pano_description"
      />
    </transition>
    <!-- Panorama settings -->
    <button
      :inert="mapOverlayActive"
      class="button-square absolute right-8 w-16 h-16 text-center block bg-secondary cursor-pointer text-sm font-bold z-20"
      :class="[ !panoMap || !panoMapLatLng ? 'bottom-20 md:bottom-8' : 'bottom-40 md:bottom-28' ]"
      :aria-label="settingsPanelActive ? 'Close settings panel' : 'Open settings panel'"
      :aria-expanded="settingsPanelActive ? 'true' : 'false'"
      @click="settingsPanelActive = !settingsPanelActive"
    >
      <IconBase
        icon="IconSettings"
        size="34px"
        class="flex justify-center"
      />
    </button>
    <transition name="fade-right">
      <ul
        v-show="settingsPanelActive && !mapOverlayActive"
        class="absolute right-8 w-60 max-h-40vh z-10 overflow-auto px-3 py-3 bg-white"
        :class="[ !panoMap || !panoMapLatLng ? 'bottom-20 md:bottom-8' : 'bottom-40 md:bottom-28' ]"
      >
        <li>
          <button
            class="flex items-center text-left mb-2 py-1 pl-2 pr-3"
            :class="{ 'bg-secondary': accessibilityControlsState }"
            :aria-label="accessibilityControlsState ? 'Close accessibility controls' : 'Open accessibility controls'"
            @click="accessibilityControlsToggle"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.71A8.71 8.71 0 1118.71 10 8.709 8.709 0 0110 18.71zm5.633-12.084a.645.645 0 01-.48.776 31.525 31.525 0 01-3.576.69c.022 4.631.531 5.645 1.1 7.1a.806.806 0 11-1.5.587 12.745 12.745 0 01-.979-3.519H9.8a12.746 12.746 0 01-.979 3.519.806.806 0 01-1.5-.587c.569-1.455 1.077-2.469 1.1-7.1a31.524 31.524 0 01-3.576-.69.646.646 0 01.3-1.256c4.243 1 5.463 1 9.714 0a.646.646 0 01.776.48zM8.46 4.912A1.54 1.54 0 1110 6.452a1.54 1.54 0 01-1.54-1.54z"/></svg>
            Accessibility<br/>Controls
          </button>
        </li>
        <li>
          <button
            class="flex items-center py-1 pl-2 pr-3"
            aria-label="Enter fullscreen mode"
            @click="fullscreenToggle"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16.295" height="16.295" viewBox="0 0 16.295 16.295" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="expand-arrows-light (2)" d="M16.284 12.075l.011 3.783a.438.438 0 01-.436.436l-3.783-.011a.438.438 0 01-.436-.436v-.364a.438.438 0 01.436-.436l2.11.011.025-.025-6.064-6.064-6.063 6.063.025.025 2.11-.011a.438.438 0 01.436.436v.364a.438.438 0 01-.436.436l-3.783.011A.438.438 0 010 15.858l.011-3.783a.438.438 0 01.436-.436h.364a.438.438 0 01.436.436l-.011 2.11.025.025 6.063-6.063-6.062-6.063-.025.025.011 2.11a.438.438 0 01-.436.436H.447a.438.438 0 01-.436-.436L0 .436A.438.438 0 01.436 0l3.783.011a.438.438 0 01.436.436v.364a.438.438 0 01-.436.436l-2.11-.011-.025.025 6.063 6.063 6.063-6.063-.025-.025-2.11.011a.438.438 0 01-.436-.436V.447a.438.438 0 01.436-.436L15.858 0a.438.438 0 01.436.436l-.011 3.783a.438.438 0 01-.436.436h-.364a.438.438 0 01-.436-.436l.011-2.11-.025-.025-6.064 6.063 6.063 6.063.025-.025-.011-2.11a.438.438 0 01.436-.436h.364a.438.438 0 01.438.436z"/></svg>
            Full screen
          </button>
        </li>
      </ul>
    </transition>
    <!-- Panorama location -->
    <button
      v-if="panoMap && panoMapLatLng"
      :inert="mapOverlayActive"
      class="button-square absolute bottom-20 md:bottom-8 right-8 w-16 h-16 z-20 text-center block bg-secondary text-sm font-bold cursor-pointer"
      aria-label="Open panorama map"
      @click="mapOverlayActive = !mapOverlayActive"
    >
      <IconBase
        icon="IconMap"
        size="32px"
        class="flex justify-center"
      />
    </button>
    <transition name="fade-right">
      <div
        v-if="panoMap && panoMapLatLng"
        v-show="mapOverlayActive"
        class="absolute left-2 md:left-8 right-2 md:right-8 top-20 md:top-8 bottom-20 md:bottom-8 z-20 bg-white"
      >
        <Map :post="post" />
        <button
          class="button-square absolute bottom-0 left-0 w-16 h-16 text-center block bg-secondary cursor-pointer text-sm font-bold"
          aria-label="Close panorama map"
          @click="mapOverlayActive = false"
        >
          <div class="close-icon flex flex-col m-auto justify-between">
            <div class="close-icon__item close-icon__item--top" />
            <div class="close-icon__item close-icon__item--btm" />
          </div>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable camelcase */
/* global removepano: false, embedpano: false */
import Map from '@/components/posts/Map.vue'

import AccessibilityControls from '@/components/buttons/AccessibilityControls.vue'
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'Panorama',

  components: {
    AccessibilityControls,
    IconBase,
    Map
  },

  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      pano: {},
      infoPanelActive: false,
      settingsPanelActive: false,
      mapOverlayActive: false,
      fullScreenActive: false
    }
  },

  watch: {
    post: {
      immediate: true,
      handler(val) {
        this.mapOverlayActive = false
        if (val) {
          this.$nextTick(() => {
            this.krpTour()
          })
        }
      }
    },
    $route() {
      if (this.$route.name === 'hotspot' || this.$route.name === 'tour-hotspot') {
        this.panToHotSpot(this.$route.params.hotspot)
      }
    }
  },

  mounted() {
    embedpano({
      xml: 'vendor/krpano.xml',
      target: 'pano',
      id: 'rama',
      mobilescale: 1.0,
      onready: this.krpReady
    })
    document.getElementById('rama').addEventListener('focus', () => {
      this.$announcer.set(this.post.pano_description)
    })
  },

  beforeUnmount() {
    removepano('rama')
    this.pano = false
  },

  computed: {
    panorama() {
      return this.post.acf
    },
    panoMap() {
      console.log(this.panorama.corners.down)
      return this.post.map
    },
    panoMapLatLng() {
      return !!(this.post.map.lat && this.post.map.long)
      || !!(this.post.map.pin_location.lat && this.post.map.pin_location.long)
    },
    accessibilityControlsState() {
      return this.$store.state.panoAccessibilityControlsOpen
    }
  },

  methods: {
    krpReady(rama) {
      this.pano = rama
      this.pano.setAttribute('tabindex', 0)
      window.activekrpanowindow = 'rama'

      window.onSpotLoaded = this.onSpotLoaded
      window.onSpotClick = this.onSpotClick
    },
    krpTour() {
      const panoImg = this.panorama?.panorama?.url ?? 'uploads/pano-01.jpg'
      const panoImgMed = this.panorama?.panorama?.sizes?.medium_large ?? 'uploads/pano-preview-01.jpg'
      const krpScene = `
        <krpano>
          <view
            fov="80.0"
            fovmin="65"
            fovmax="105"
            limitview="lookat"
            hlookat="${this.panorama.position.horizontal}"
            vlookat="${this.panorama.position.vertical}"
            hlookatmin="${this.panorama.corners.right || '360'}"
            hlookatmax="${this.panorama.corners.left || '-360'}"
            vlookatmin="${this.panorama.corners.up || '-90'}"
            vlookatmax="${this.panorama.corners.down || '90'}"
            fisheye="0"/>
          <preview url="${panoImgMed}?nocache=${Date.now()}"/>
          <image>
            <sphere url="${panoImg}"/>
          </image>
        </krpano>
      `

      const krpCall = `loadxml(${krpScene}, null, MERGE, blend(1, linear));`

      this.pano.call(krpCall)

      if (this.post.children) {
        this.krpAddSpots()
      }
    },

    krpAddSpots() {
      const postChildren = this.post.children.filter(({ entry }) => entry)
      const hotspots = postChildren.reduce((crop, item) => {
        const slug = `${item.entry.post_name}`

        const hotspot = `
          <button
            role="link"
            aria-label="View ${item.entry.post_title}"
            class="h-14 w-14 hotspot group relative h-14 text-base rounded-full focus-a font-sans text-white bg-white"
          >
            <div class="popup absolute left-0 top-0 h-full pl-16 pr-10 bg-secondary rounded-full opacity-0 transition">
              <div class="h-full flex-c">
                <div>
                  <span class="block mr-1 text-black"> ${item.entry.post_title} </span>
                </div>
                <img src="${require('@/assets/icon-arrow.svg')}" alt="" class="absolute right-3 h-3 w-auto">
              </div>
            </div>

            <div class="relative flex-c rounded-full mx-auto ${item.entry.post_type === 'post' ? 'px-4' : 'px-3'}">
              <img src="${require(`@/assets/icon-hs-${item.entry.post_type}.svg`)}" alt="" role="presentation" class="relative h-full w-full" />
            </div>
          </button>
        `

        return `
          ${crop}
          addhotspot(${slug});
          set(hotspot[${slug}].ath, ${item.position.horizontal});
          set(hotspot[${slug}].atv, ${item.position.vertical});
          set(hotspot[${slug}].type, text);
          set(hotspot[${slug}].html, '${escape(hotspot)}');
          set(hotspot[${slug}].padding, 0);
          set(hotspot[${slug}].wordwrap, false);
          set(hotspot[${slug}].interactivecontent, true);
          set(hotspot[${slug}].zoom, false);
          set(hotspot[${slug}].bg, false);
          set(hotspot[${slug}].renderer, css3d);
          set(hotspot[${slug}].onclick, looktohotspot(${slug}, 50); js(onSpotClick(${slug})));
          set(hotspot[${slug}].onloaded, js(onSpotLoaded(${slug})));

        `
      }, '')

      const krpCall = `${hotspots}`

      this.pano.call(krpCall)
    },

    getSprite(slug) {
      return this.pano.get(`hotspot[${slug}].sprite`)
    },

    onSpotClick(slug) {
      this.$store.commit('toggleHotspotContent', slug)
      this.$emit('hotSpotClick', slug)
      this.panToHotSpot(slug)
      window.dataLayer.push({ event: 'hotspotClick' })
    },

    panToHotSpot(slug) {
      this.pano.call(`looktohotspot(${slug}, 50);`)
    },

    showHotSpotPopup(sprite) {
      const popup = sprite.querySelector('.popup')
      sprite.style.zIndex = 2002
      popup.classList.add('opacity-100', 'pointer-events-auto')
    },

    hideHotSpotPopup(sprite) {
      const popup = sprite.querySelector('.popup')
      sprite.style.zIndex = 2001
      popup.classList.remove('opacity-100', 'pointer-events-auto')
    },

    onSpotLoaded(slug) {
      const sprite = this.getSprite(slug)

      // Why oh why to we have to wait to get the button element...
      setTimeout(() => {
        const target = sprite.querySelector('.hotspot')

        // To keep track of a click or a focus key event
        let mouseDown = false

        // Try block as errors when guided tour intro is open
        // Think it's because the pano is aria hidden ??
        try {
          target.addEventListener('mousedown', () => {
            mouseDown = true
          })

          target.addEventListener('mouseup', () => {
            mouseDown = false
          })

          target.addEventListener('mouseover', () => {
            if (!this.touch) {
              this.showHotSpotPopup(sprite)
            }
          })

          target.addEventListener('mouseleave', () => {
            this.hideHotSpotPopup(sprite)
          })

          target.addEventListener('focus', (e) => {
            if (!mouseDown) {
              this.showHotSpotPopup(sprite)
              this.panToHotSpot(slug)
            }

            e.target.addEventListener('keyup', () => {
              const key = event.key || event.keyCode
              if (key === 'Enter' || key === '13') {
                this.onSpotClick(slug)
              }

              if (key === 'Escape' || key === '27') {
                this.hideHotSpotPopup(sprite)
                document.getElementById('rama').focus()
              }
            })
          })

          target.addEventListener('blur', (e) => {
            this.hideHotSpotPopup(sprite)
            e.target.removeEventListener('keyup', () => {})
          })
        } catch (err) {
          console.error(err)
        }
      }, 10)
    },

    toggleGyro() {
      this.pano.call('switch(plugin[gyro].enabled)')
    },

    enterVr() {
      this.pano.call('webvr.toggleVR()')
    },

    rvonentervr() {
      this.pano.call('set(events[vr_events].onviewchange, followMenuAction()); set(hotspot[followMenuPrev].visible, true); set(hotspot[followMenuNext].visible, true);')
    },

    rvonexitvr() {
      this.pano.call('set(events[vr_events].onviewchange, null); set(hotspot[followMenuPrev].visible, false); set(hotspot[followMenuNext].visible, false)')
    },

    rvvrnext() {
    },

    rvvrprevious() {
    },

    fullscreenToggle() {
      this.pano.call('switch(fullscreen);')
      if (this.fullScreenActive) {
        this.fullScreenActive = false
      } else {
        this.fullScreenActive = true
      }
    },

    accessibilityControlsToggle() {
      const state = this.$store.state.panoAccessibilityControlsOpen
      if (state) {
        this.$store.commit('togglePanoAccessibilityControls')
      } else {
        this.$store.commit('togglePanoAccessibilityControls')
      }
    }
  }
}
</script>

<style lang="scss">
#rama {
  z-index: 1;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 6px solid transparent;
    pointer-events: none;
    user-select: none;
    transition: border-color 0.4s;
  }

  &:focus:after,
  &:focus-within:after {
    border-color: var(--color-secondary);
  }
}
</style>
